<template>
    <div class="search-bar--nav grid-x grid-margin-x align-center">
        <h3 class="cell small-12 xlarge-10 xxlarge-8 headline text-spacing--medium">{{ searchbarTitle }}</h3>
        <div class="cell small-12 xlarge-10 xxlarge-8">
            <div class="grid-x grid-margin-x align-justify">
                <div class="cell small-12 medium-auto search-bar">
                    <label
                        class="mdc-text-field mdc-text-field--filled mdc-text-field--with-leading-icon"
                        ref="search-field"
                    >
                        <span class="mdc-text-field__ripple"></span>
                        <span class="mdc-floating-label text--body2" id="input7">{{ searchLabel }}</span>
                        <i aria-hidden="true" class="icon icon--ActionSearch mdc-text-field__icon"></i>

                        <input
                            class="mdc-text-field__input"
                            type="text"
                            aria-labelledby="input7"
                            v-model="searchTerm"
                            @focus="triggerSuggestions = true"
                            @blur="triggerSuggestions = false"
                            @keyup.enter="search"
                        />

                        <span class="mdc-line-ripple"></span>
                    </label>

                    <div
                        class="search__suggestions grid-x"
                        v-if="searchTerm.length >= 3 && searchResult.success && searchResult.result.length"
                    >
                        <p class="cell small-12 color--accent5">Suggestions:</p>

                        <ul
                            class="cell small-12 grid-x grid-margin-x list--blank suggestions"
                            v-if="searchResult.success && searchResult.result.length"
                        >
                            <li
                                class="cell small-12 clickable text-spacing--small"
                                v-for="result in searchResult.result"
                                :key="result"
                                @click="setSearchTerm(result)"
                            >
                                {{ result }}
                            </li>
                        </ul>
                    </div>
                </div>

                <button
                    class="cell shrink mdc-button mdc-button--unelevated mdc-button--secondary"
                    :disabled="!searchAllowed"
                    ref="search-button"
                    @click="search"
                >
                    <div class="mdc-button__ripple"></div>
                    <span class="mdc-button__label">{{ buttonLabel }}</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { MDCTextField } from "@material/textfield";
import { MDCRipple } from "@material/ripple";

import { ResponsiveMixin } from "../../../js/_mixins/responsive";

import axios from "axios";

export default {
    name: "SiteSearch",

    mixins: [ResponsiveMixin],

    props: {
        apiUrl: String,
        appUrl: String,
        langId: String,
        siteId: String,
        targetgroupId: String,

        searchbarTitle: {
            type: String,
            default: "dSPACE Search",
        },

        searchLabel: {
            type: String,
            default: "Search",
        },

        buttonLabel: {
            type: String,
            default: "Search",
        },
    },

    data() {
        return {
            searchField: null,
            searchButton: null,
            searchTerm: "",

            suggestionQuery: "_action=search.api.autocomplete",
            searchQuery: "#order=prevalence&term=",

            //Todo: Evtl. refactor for snake case mapping
            postData: {
                // eslint-disable-next-line camelcase
                lang_id: this.langId,

                // eslint-disable-next-line camelcase
                site_id: this.siteId,

                // eslint-disable-next-line camelcase
                targetgroup_id: this.targetgroupId,
                term: "",
            },

            searchResult: {},
            triggerSuggestions: false,
            searchAllowed: false,
        };
    },

    mounted() {
        this.searchField = new MDCTextField(this.$refs["search-field"]);
        this.searchButton = new MDCRipple(this.$refs["search-button"]);
    },

    methods: {
        async getSuggestions(term) {
            this.postData.term = term;

            let response;

            try {
                response = await axios.post(`${this.apiUrl}?${this.suggestionQuery}`, this.postData);

                if (response) {
                    this.searchResult = response.data;
                }
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log(error);
            }
        },

        setSearchTerm(term) {
            this.triggerSuggestions = false;
            this.searchTerm = term;

            if (this.searchAllowed) {
                window.location.href = `${this.appUrl}?${this.searchQuery}${this.searchTerm}`;
            }
        },

        search() {
            if (this.searchAllowed) {
                window.location.href = `${this.appUrl}?${this.searchQuery}${this.searchTerm}`;
            }
        },
    },

    watch: {
        searchTerm: function (searchVal) {
            this.searchAllowed = searchVal.length >= 1;

            if (this.searchAllowed && this.triggerSuggestions) {
                this.getSuggestions(searchVal);
            }
        },
    },
};
</script>
