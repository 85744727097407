<template>
    <header class="grid-x grid-margin-x header">
        <slot></slot>
    </header>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MainNavigation from "../navigation/MainNavigation.vue";
import { ResponsiveMixin } from "../../../js/_mixins/responsive";
import { ScrollPosition } from "../../../js/_mixins/scrollPosition";

export default {
    name: "header-component",

    mixins: [ResponsiveMixin, ScrollPosition],

    components: {
        //Needs to be available as Navigation will used in inline template
        // eslint-disable-next-line vue/no-unused-components
        MainNavigation,
    },

    data() {
        return {
            activeIndex: 0,
            navigationOpen: false,
            ready: false,
            mainNavHeight: "auto",
            mainVisibility: "hidden",
            navigationMaxHeight: { "max-height": "auto", visibility: "hidden" },
            transitionName: "pop",
            headerTopInitialHeight: 0,
        };
    },

    computed: {
        ...mapGetters("mainStore", ["activeIdx", "roomForNavigation", "secondCollision", "subNavigationOpen"]),

        hideClaim() {
            return !this.roomForNavigation || this.isScrolledDown;
        },

        headerTopHeight() {
            if (!this.headerTopInitialHeight || !this.roomForNavigation) return "";

            const scrollDiff = this.headerTopInitialHeight - this.lastKnownScrollPosition;

            return scrollDiff <= 0 && this.lastKnownScrollPosition > 0 ? "height: 0;" : `height: ${scrollDiff}px;`;
        },
    },

    methods: {
        ...mapActions("mainStore", ["resetActiveIdx", "setSubNavigationOpen"]),

        toggleMobileNavi() {
            this.navigationOpen = !this.navigationOpen;

            if (this.navigationOpen) {
                document.documentElement.classList.add("overflow-y-hidden");
            } else {
                document.documentElement.classList.remove("overflow-y-hidden");
                this.resetActiveIdx();
            }
        },

        calcMaxHeight() {
            this.mainNavHeight = `${this.$refs.navigation.getBoundingClientRect().height}px`;

            this.navigationMaxHeight = { "max-height": this.mainNavHeight, visibility: this.mainVisibility };
        },

        openLoginMobile(idx) {
            if (!this.isDesktop || !this.roomForNavigation) {
                if (this.activeIdx === idx) {
                    this.resetActiveIdx();
                    this.toggleMobileNavi();
                } else {
                    this.setActiveIdx(idx);
                    if (!this.navigationOpen) {
                        this.toggleMobileNavi();
                    }
                }

                this.activeIndex = this.activeIdx;
            }
        },

        getHeaderTopInitialHeight() {
            return this.$refs["header-top"].getBoundingClientRect().height;
        },
    },

    mounted() {
        this.activeIndex = this.activeIdx;
        this.transitionName = this.roomForNavigation ? "pop" : "push";
        this.headerTopInitialHeight = this.getHeaderTopInitialHeight();
        this.ready = true;

        this.$refs.navigation.addEventListener("transitionstart", (event) => {
            if (event.target.className === this.$refs.navigation.className) {
                this.setSubNavigationOpen(true);
            }
        });

        this.$refs.navigation.addEventListener("transitionend", (event) => {
            if (event.target.className === this.$refs.navigation.className) {
                this.setSubNavigationOpen(!this.navigationOpen);

                if (!this.navigationOpen) {
                    this.resetActiveIdx();
                }
            }
        });
    },

    watch: {
        roomForNavigation: {
            handler: function () {
                if (this.roomForNavigation) {
                    this.navigationOpen = false;
                    this.navigationMaxHeight = { "max-height": "auto", visibility: "visible" };
                    this.transitionName = "pop";
                    this.resetActiveIdx();
                }
            },

            immediate: true,
        },

        isMobile: {
            handler: function () {
                if (this.isMobile && this.ready) {
                    this.navigationOpen = false;
                    this.calcMaxHeight();
                    this.transitionName = "push";
                    this.resetActiveIdx();
                }
            },

            immediate: true,
        },
    },
};
</script>
